.blogs-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin:0 60px;
    align-self: center;
}

.blog-unit{
    width: 600px;
    height: 300px;
    max-width: calc(100vw - 16px);
    max-height: calc(0.66667 * 100vw);
    margin: 16px;
    overflow: hidden;
}

.image-block img{
    width: 600px;
    height: 300px;
    max-width: calc(100vw - 16px);
    max-height: calc(0.66667 * 100vw);
}

.cover-plate{
    width: 600px;
    height: 300px;
    max-width: calc(100vw - 16px);
    max-height: calc(0.66667 * 100vw);
    position: absolute;
    z-index: 9;
    background-color: #212629bb;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cover-plate:hover{
    background-color:#212629e7 ;
}

.blog-unit:hover .block-image{
    width: 800px;
}

.blog-title{
    font-size: 30px;
    font-family: 'Arvo', sans-serif;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
}

.blog-title:hover{
    color: #555555;
}
.blog-dets{
    margin-top: 16px;
}

.det-unit{
    margin: 0 8px;
}

.comments{
    background-color: #ffffff;
    width: 75vw;
}

.det-caption{
    font-family: "Arvo",sans-serif;
    font-weight: 600;
    margin-right: 8px;
    font-style: italic;
    font-size: 16px;
    color: #ffffff;
}

.det-text{
    font-family: "Arvo", sans-serif;
    color: #daedeec9;
}

.padded{
    padding: 20px;
}

.blog-image{
    width: 800px;
    height: 400px;
}

.blog-details{
    padding: 36px ;
    width: calc(75vw - 72px);
    background-color: #ffffff;
}

.blog-det-title{
    font-family: "Arvo",sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin: 32px 0 16px 0;
    color: #1d1d21;
}

.blog-data{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    margin-bottom: 24px;
}

.data-unit{
    font-family: "Arvo", sans-serif;
    font-size: 16px;
    color: #555555;
    margin: 0 16px;
}

.separator{
    border: 0.5px solid #dedede;
    height: 24px;
    width: 0.5px;
}

.blog-body{
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    color: #555555;
}

.blog-body img{
    width: 600px;
    max-width: calc(100vw - 72px);
}

hr{
    margin: 16px 0;
}

.prompt-caption, .comment-caption{
    font-size: 24px;
    font-weight: 600;
    padding: 20px 0;
    color: #1d1d21;
    font-family: "Ubuntu", sans-serif;
    margin: 0 30px;
}



.share-prompt{
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.prompt-buttons{
    width: 150px;
}

.prompt-button{
    border: none;
    background: none;
    color: #555555;
    font-size: 18px;
    margin: 8px 0;
}

.prompt-button:hover {
    font-size: 32px;
}

.comments-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 16px 0;
}

.comments-input-group{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 16px;
}

.input-caption{
    font-family: "Open sans", sans-serif;
    color: #888888;
}

.text-input{
    height: 36px;
    font-size: 16px;
    color: #555555;
}

.long-text{
    min-height: 150px;
    padding: 8px;
}

.comments-section {
    width: calc(0.6 * 75vw);
}

.comm-unit{
    margin-bottom: 32px;
    margin-left: 36px;
    border: 1px solid #888888;
}

.comm-data{
    margin: 4px 0;
    display: flex;
    padding: 8px;
    justify-content: space-between;
}

.comm-meta{
    font-size: 14px;
    font-family: "Arvo", sans-serif;
    color: #888888;
    margin: 0 16px;
}

.meta-cap{
    font-size: 18px;
    font-weight: 600;
    color: #1d1d21;
}

.comm-text{
    padding: 16px;
    font-size: 14px;
    color: #555555;
    line-height: 24px;
    margin: 0 16px;
}



.submit-button{
    width: 200px;
    height: 50px;
    background-color: #daa520;
    color: #ffffff;
    font-family: "Arvo", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
}

.share-icon{
    font-size: 16px;
    color: #1d1d21;
}

.comments-input{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cover-img{
    width: 600px;
    height: 300px;
    max-width: 90%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.select-cover{
   display: none;
}

.page-toggle{
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-btn{
    padding: 8px;
    width: 150px;
    border: 1px solid #1d49db;
    background-color: #efefef ;
    font-family: sans-serif;
    font-weight: 600;
    color: #1d49db;
}

.current-page{
    background-color: #1d49db;
    color: #ffffff;
}

.cover-picture{
    width: 600px;
    height: 300px;
    max-width: 100%;
}

.event-unit{
    width: 500px;
    height: 300px;
    max-width: 100vw;
    margin: 16px auto;
}

.unit-cover{
    position: absolute;
    z-index: 2;
    width: 500px;
    height: 300px;
    max-width: 100vw;
    background-color: #1d1d216f;
}

.cover-buttons{
    display: flex;
    padding: 8px 16px;
    justify-content: flex-end;
}

.cover-bttn{
    background: none;
    border: 1.5px solid #ffffff;
    height: 30px;
    padding-bottom: 8px;
    padding-top: 2px;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    
}

.more-flap{
    position: absolute;
    z-index: 9;
    background-color: #ffffff;
    width: 100px;
    padding: 16px;
    border-radius: 8px 0px 8px 8px;
    display: none;
    right: 5px;
}

.seen{
    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.event-img{
    width: 100%;
    height: 100%;
}

.more-bt{
    border-radius: 4px;
    border: 1px solid #2424b2;
    padding: 4px;
    font-weight: 500;
    color: #ffffff;
    background-color: #1d49db;
    margin: 8px 0;
    font-size: 18px;
}

.close-bt{
    border: 1px solid red;
    background-color: red;
}

.flap-close{
    font-size: 24px;
    margin: 0 8px;
}

/** blog posts on tablet and phone **/
@media(max-width: 768px) {
    .blogs-container{
        margin: auto 0;
        justify-content: center;
    }

    .blog-unit{
        margin: 16px 0;
    }
    .blog-details{
        width: calc(100vw - 72px);
    }

    .blog-image{
        width: calc(100vw - 72px);
        height: calc(0.6667 * 100vw);
    }

    .comments{
        width: 100vw;
    }

    .comments-section{
        width: 100vw;
    }

    .comm-unit{
        margin-bottom:  36px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .data-unit{
        font-size: 13px;
    }

    .comments-input-group{
        width: calc(100vw - 16px);
    }
}


@media(max-width: 400px){
    .prompt-caption{
        display: none;
    }

    
}