.slide-container{
  width: 100%;
  max-width: 100vw;
  height: 90vh;
  margin: 0 auto;
}

.slide-frame{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.slide-pics{
    width: 100%;
    max-width: 100vw;
}

.fade-class{
    width: 100%;
    margin: 0 auto;
    max-width: 100vw;
}

.slide-caption{
    text-wrap: wrap;
    width: 100%;
    height: 90vh;
    position: absolute;
    z-index:  9;
    margin-top: 90vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #14146a1e;
    justify-content: center;
    color: #ffffff;
}

.caption-text{
  width: 70vw;
  height: 80vh;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 19;
  position: absolute;
  color: #ffffff;
  font-size: 40px;
  font-family: "Arvo","Ubuntu", sans-serif;
  font-weight: 600;
}

.anim{
  animation: showup 1.2s linear;
}

.slideup{
  animation: slide 1.2s ease;
}

@keyframes slide {
  from{
    transform: translateY(0);
  }
  to{
    transform: translateY(30);
  }
}


@keyframes showup {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.more-btn{
  background-color: #d8cd31;
  color: #0a1c3e;
  font-size: 20px;
  font-family: "Arva", sans-serif;
  border: none;
  padding: 20px;
  width: 200px;
  font-weight: 600;
  margin-top: 20px;
}

.frame-cover{
  width: 100%;
  height: 90vh;
  background-color: #0a1c3e;
  z-index: 99;
}

@media(max-width: 768px) {
  .slide-pics{
    width: 100vw;
    height: 50vh;
  }

  .frame-cover{
    height: 50vh;
    margin-top: 110px;
  }

  .caption-text{
    font-size: 30px;
    height: 50vh;
  }
  
  .slide-caption, .slide-container, .slide-frame{
    height: 50vh;
  }
}