.blog-form{
    width: 700px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    border: 1px solid #888888;
    border-radius: 4px;
}
.blog-input{
    min-height: 300px;
    background-color: #f1f7f7;
    margin: 8px;
    padding: 8px;
}

.blog-input:focus{
    outline: none;
}

.title{
    margin: 16px 8px;
    border: none;
    outline: none;
    padding: 0 16px;
    text-wrap: wrap;
    min-height: 60px;

}

.title:focus{
    border: 1px solid #2a4d5f;
    outline: none;
}

.title::placeholder{
    font-size: 24px;
    font-family: sans-serif;
    font-style: italic;
    text-align: center;
}

.blog-input:focus{
    border: none;
}

.blog-input img, .prev img{
    width: 200px;
}



.save-blog-box{
    margin: 0 32px;
    border: 1px solid #454545;
    border-radius: 8px;
    width: 600px;
    padding: 20px;
}

.blog-create-btn{
    background-color: #2a4d5f;
    color: #ffffff;
    font-family: "Arvo", sans-serif;
    font-weight: 500;
    border: none;
    padding: 8px;
    border-radius: 8px;
    margin: 16px;
}

.toolbar-container{
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tbr-btn{
    margin: 4px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #2a4d5f;
}
.tbr-btn:hover{
    opacity: 0.7;
}
.cls{
    background-color: red;
    font-weight: 600;
    color: #ffffff;
    border: none;
}
.pic-add-btn {
   display: none;
}



.hidden{
    display: none;
}

.file-select{
    width: fit-content;
    color: #daa520;
    padding: 8px;
    background-color: #14181b;
    margin: 16px 0;
}

.file-img{
    margin-left: 16px;
}

