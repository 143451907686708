.ayembe{
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 6vw;
    color: #101928;
    text-align: center;
    margin: 20px 0;
    text-wrap: wrap;
}

.abbrev{
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 8vw;
    color: #101928;
    text-align: center;
    margin: 20px 0;
    text-wrap: wrap;
}

.mid-caption{
    font-size: 24px;
    color: #2e3167;
    font-weight: 600;
    font-family: "Arvo", sans-serif;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 16px;
}



.row-inv-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: calc(100vw - 128px);
    align-self: center;
    padding: 20px;
    flex-wrap: wrap;
}

.row-unit{
    margin: 0 32px;
    width: calc(50vw - 128px);
    max-width: 100vw;
}

.img-box{
    width: 516px;
    max-width: 100vw;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.box-image{
    width: 500px;
    max-width: calc(100vw - 16px);
}

.unit-caption{
    margin-top: 20px;
    font-family: "Arvo", sans-serif;
    font-weight: 600;
    margin-left: 16px;
}

.unit-text{
    font-family: "Open sans", sans-serif;
    width: 484px;
    max-width: calc(100vw - 16px);
    padding: 8px 16px;
    text-align: justify;
    line-height: 24px;
}

/* new changes */


.section-label{
    font-family: "Ubuntu", sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #2e3167;
    margin: 8px;
    padding: 12px;
}

.info-section{
    display: flex;
    align-items: center;
    padding: 30px;
}

.square{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-image{
    width: 55%;
    display: flex;
    justify-content: flex-start;
}

.info-pic{
    width: 400px;
}

.black-box{
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    background-color: #101928;
    color: goldenrod;
    max-width: 100vw;
}

.why-point{
    display: flex;
    flex-direction: row;
    margin: 16px 0;
    padding: 8px;
    align-items: flex-start;
}

.why-text{
    font-size: 20px;
    font-family: "Arvo", sans-serif;
    font-weight: 700;
    color: #ffffff;
}

.why-icon{
    width: 40px;
    margin-right: 16px;
}

.why-topic{
    display: flex;
    flex-direction: column;
}

.why-context{
    font-size: 16px;
    color: #888888;
    line-height: 24px;
}

.page-cover{
    height: 350px;
    width: 100vw;
    z-index: -1;
    position: fixed;
    top: 0;
}

.cover-layer{
    background-color: #052b7e5e;
    height: 350px;
    width: 100vw;
    z-index: 1;
    position: absolute;
    top: 0;
}

.pic-caption{
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Arvo", sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
}

.page-pic{
    height: 350px;
    overflow: hidden;
    z-index: -2;
   
}

.home-pic{
    width: 100vw;
}

.page-body{
    background-color: #e9edef;
    margin-top: 0px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.rect{
    padding: 16px;
    border: 1px solid #2e3167;
    background-color: #101928;
    color: goldenrod;
}

.obj-unit{
    display: flex;
    margin: 16px 0;
    align-items:flex-start;
    font-size: 20px;
}
 .obj-icon{
    color: goldenrod;
    margin-right: 16px;
 }

 .obj-text{
    font-family: "Arvo", sans-serif;
    color: #ffffff;
    line-height: 30px;
 }

 .logos{
    padding: 16px 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
 }

 .money-logo{
    margin: 16px;
 }

/* Tablets and mobile phones */
@media(max-width: 500px) {

    .logos{
        justify-content: center;
    }
   
    .row-unit{
        margin: auto;
        margin-bottom: 32px;
        width: 100vw;

    }
    .unit-text{
        padding: 8px;
    }

    .page-pic{
        height: 200px;
    }

   .row-inv-box{
    padding: 0;
    flex-direction: column;
    width: 100vw;
   }

    .section-label{
        font-size: 20px;
    }

    .section-image{
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .info-pic{
        width: 90vw;
    }

    .info-section{
        flex-direction: column-reverse;
        padding: 20px 0;
    }

     .page-cover, .cover-layer{
        height: 200px;
    }

    .home-pic{
        height: 200px;
    }

    .pic-caption{
        text-align: center;
        height: 200px;
    }

    .services-wrapper{
        justify-content: center;
    }
}

@media (max-width: 500px){
   
}