.alert-screen{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #2a4d5f4f;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-container{
    width: 400px;
    max-width: 95vw;
    background-color: #ffffff;
    border-radius: 16px;
}

.alert-content{
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0a212c;
}

.alert-caption{
    font-family: "Arvo", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.alert-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin: 20px 0;
}

.alert-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
}

.alert-button{
    border-radius: 30px;
    padding: 8px 16px;
    border: none;
    font-family: sans-serif;
    border: 1px solid #0c364b;
    font-weight: 600;
    margin: 0 16px;
}

.dark-button{
    background-color: #0c364b;
    color: #ffffff;
}

.light-button{
    background-color: #ffffff;
    color: #0c364b;
}

