
.page-bg{
    background-image: url("/public/images/pics/homebranch.jpg");
    background-size: cover;
    width: 100vw;
    height: 350px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-title{
    font-family: "Arvo",sans-serif;
    color: #ffffff;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    align-self: center;
    font-weight: 700;
    font-size: 50px;
    height: 100%;
    z-index: 3;
    background-color: #052b7e5e;
}

.about-black-bg{
    background-image: url("/public/images/pics/homebranch.jpg");
    background-size: contain;
    width: 700px;
    max-width: 100vw;
    margin-bottom: 32px;
}

.info-unit{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bg-cover{
    border: 1px solid #daa520;
    flex-grow: 1;
    padding: 24px;
}
.about-text{
    font-family: "Arvo",sans-serif;
    color: #ffffff;
    padding: 16px;
    text-align: justify;
    line-height: 48px;
    font-size: 16px;
    align-self: center;
    font-weight: 700;
    font-size: 30px;
    z-index: 3;
    background-color: #090424d3;
}



.about-black-bg{
    background-image: url("/public/images/pics/homebranch.jpg");
    background-size: contain;
    width: 700px;
    max-width: 100vw;
}

.branch-box{
    display: flex;
    flex-direction: column;
    margin: 24px auto;
}

.branch-unit{
    display: flex;
    padding: 8px 0;
    align-items: flex-start;
    justify-content: space-between;
}

.sqr{
    border: 1px solid #daa520;
    padding: 8px;
    background-color: #090424d3;
    margin: 16px;
    flex-grow: 1;
}

.branch-pic{
    width: 600px;
    height: 400px;
    max-width: 100vw;
    max-height: calc(0.6667 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    margin: 16px;
}

.branch-img{
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    max-width: calc(100vw - 32px);
}

.branch-info, .info-text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.branch-info{
    background-color: #090424d3;
    width: 600px;
    height: 400px;
    max-width: calc(100vw);
    margin: 16px;
}

.branch-cap{
    font-weight: 600;
    font-family: "Arvo", sans-serif;
    font-size: 32px;
    color: #daa520;
    display: flex;
    justify-content: center;
}

.text-caption{
    font-weight: 600;
    font-family: "Arvo", sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 8px;
}

.info-data{
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
}

.info-icon{
    font-size: 40px;
    margin-right: 8px;
}

.info-dets{
    font-family: "arvo",sans-serif;
    color: #888888;
    font-size: 16px;
}

.info-more{
    display: flex;
    flex-direction: column;
    line-height: 24px;
}

@media (max-width: 768px) {
    .branch-unit {
        flex-direction: column;
        margin: 16px auto;
    }

    .branch-pic, .branch-info{
        margin: 0 auto;
        min-height: calc(0.6667 * 100vw);
    }
}

