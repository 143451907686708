
.page-caption, .page-header{
    color: #454545;
    font-family: "Arvo", sans-serif;
    max-width: 90vw;
    font-size: 30px;
    font-weight: 600;
    
    text-align: center;
}



.line-icon{
    color: #298fb7;
    font-size: 26px;
    margin-right: 24px;
}
.line-text{
    font-size: 18px;
    color: #888888;
    font-family: "Open Sans", sans-serif ;
}
.page-line{
    margin: 8px 4px;
    display: flex;
    flex-direction: row;
}
.containers{
    display: flex;
    flex-direction: column;
    margin: 16px auto;
    padding: 0 24px;
}

.types-container{
    background-color: #101928;
    color: #888888;
    font-family: "Arvo", sans-serif;
    line-height: 24px;
    padding: 24px 8px;
}

.types-header{
    font-weight: 700;
    font-family: "Arvo", sans-serif;
    color: #f0f8ff;
    margin-bottom: 16px;
}

.box-square{
    border: 1px solid #9295a1;
    padding: 16px;
    width: 600px; 
}

.line-tick{
    font-size: 24px;
    color: #daa520;
    margin-right: 24px;
}
.account-details{
    padding: 32px;
    display: flex;
    flex-direction: column;
}


.topic-caption{
    font-size: 40px;
    font-family: "Arvo", sans-serif;
    color: #454545;
    font-weight: 600;
    color: #101928;
    text-align: center;
}

.topic-image-box{
    background-color: #ffffff;
    padding:  8px;
}
.fact-box{
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    color: #878787;
    display: flex;
    flex-direction: row;
    margin: 16px 8px;
}
.fact-bullet{
    width: 50px;
    padding: 0 5px;
}
.all-accounts{
    display: flex;
    margin: auto;
    max-width: 100%;
}
.account-facts{
    margin: 10px;
    padding: 5px;
}
.topic-container{
    display: flex;
    flex-direction: row;
}
.topic-img{
    width: 600px;
    max-width: calc(100vw - 16px);
    height: 400px;
    max-height: calc(0.66667*100vw);
}

@media(max-width: 768px) {
    .account-details{
        padding: 0;
    }

    .box-square{
        width: calc(100vw - 48px);
    }

    .topic-container{
        flex-direction: column;
    }
}