footer{
    background-color: #298fb7;
    color: #ffffff;
    font-family: sans-serif;
    padding: 16px;
    padding-bottom: 4px;
}

.top-footer{
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.copy-bottom{
    font-size: 12px;
    font-family: "Roboto",sans-serif;
}

.bottom-small{
    margin: 32px;
}

.link-caption{
    font-size: 24px;
    font-weight: 700;
}

.link-text{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    text-decoration: none;
    margin: 4px;
}

.link-text:visited{
    color: #ffffff;
}

.link-section{
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.info-box{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-text{
    font-family: "Nunito Sans",sans-serif;
    font-size: 40px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title{
    font-family: "Nunito Sans",sans-serif;
    font-weight: 600;
    font-size: 24px;
}
.location{
    font-family: "Nunito Sans",sans-serif;
    margin: 8px 0;
    font-size: 16px;
}

@media(max-width: 768px){
    .top-footer{
       flex-wrap: wrap;
    }

    .logo img{
        width: 100px;
        height: 75px;
        margin: 0 4px;
    }

}

