    *{
        margin:0;
        
    }


    .transparent-header{
        padding: 15px 0;
        display: flex;
        flex-grow: 1;
        width: 100vw;
        top: 0;
        flex-direction: row;
        align-items: center;
        background: none;
        position: fixed;
        z-index: 999;
        font-family: "Quicksand",sans-serif;
        background-color: #ffffff;
        box-shadow: 0 2px 3px 0, 0 1px 2px #2e3167;
    }

    .logo-section{
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .tab-icon{
        margin-right: 8px;
    }

    .header-logo{
        width:60px;
        height: 60px;
    }
    
    .title-section{
        font-family:'Roboto';
        font-weight: 900;
        font-size: 24pt;
        display: flex;
        flex-direction: row;
        height: 80px;
        align-items: center;
        color: #2e3167;
        margin-right: 40px;
        padding: 0 16px;
    }

    .menu-tabs{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 80px;
    }

    .menu_link{
        text-decoration: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: #2e3167;
        margin: 0 16px;
    }

    .menu_button{
        color: #2e3167;
        display: flex;
        align-items: center;
        font-size: 12pt;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Arvo",sans-serif;
        font-weight: 600;
    }
    .menu_button:hover{
        opacity: 0.6;
        color: goldenrod;
    }

    .polybox{
        z-index: 9;
        background-color: #7bc2db;
        width: 400px;
        padding: 0 0 24px 0;
        border-radius: 20px ;
        margin-top: 50px;
        display: none;
    }
    .options:hover .polybox{
        display: flex;
    }
    .box-container{
        width: 100%;
        height: 40px;
        border-radius: 20px ;
        background-color: #7bc2db;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .twisted_box{
        transform: rotate(45deg);
        width: 50px;
        height: 50px;
        background-color: #7bc2db;
        margin-top: -10px;
    }
    .option_button{
        width: 150px;
        display: inline-flex;
        justify-content: center;
    }
    .box-menu{
        margin-top:30px;
    }
    .menu-list{
        background-color: #7bc2db;
    }
    .list-item{
        background-color: #7bc2db;
        list-style: none;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
        font-size: 14pt;
        font-weight: 700;
        margin: 20px 0;
        color: #3b3a3a;
    }

    .menu-img{
        width: 30px;
        height: 13px;
        margin: 0 15px;
    }

    .menu-img1{
        width: 20px;
        height: 20px;
        margin: 0 15px;
    }

    .menu-link{
        cursor: pointer;
        text-decoration: none;
    }

    .fr{
        color: #2e3167;
        font-family: "Aref Ruqaa";
        background-color: #ffffff;
        border: none;
        cursor: pointer;
        font-weight: 600;
        width: 100px;
    }

    .menu_mobile_view{
        display: none;
    }

    .auth{
        height: 80px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .auth_button{
        border: 1.5px solid #2e3167;
        border-radius: 20px;
        padding: 8px;
        background: none;
        color: #2e3167;
        cursor: pointer;
    }

    .button-text{
        margin-left: 16px;
        font-family: "arvo", sans-serif;
        font-weight: 500;
    }

    .hide-drop-menu{ display: none;}

    

    .r-img{
        width: 30px;
        height: 30px;
        border-radius: 15px;
    }

    .invisible{
        display: none;
    }

    .center-page{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background-color: #efefef;
    }

    .large-text{
        font-size: 50px;
        font-weight: 600;
        font-family: sans-serif;
    }

    .current{
        color: goldenrod;
    }

    .auth_button:hover{
        border: 1px solid goldenrod;
        color: goldenrod;
    }

    /* mobile view =============================== */
    @media (max-width: 768px) {

        header{
            justify-content: flex-start;
        }

        .avatar-frame {
            display: flex;
            justify-content: center;
        }
        .r-img{
            width: 80px;
            height: 80px;
            border-radius: 40px;
        }
        .menu-tabs{
            display: flex;
            flex-direction: column;
            justify-content:flex-start;
            position: absolute;
            z-index: 199;
            left: 0;
            width: 250px;
            height: 100vh;
            top: 80px;
            background-color: #ffffff;
            padding-top: 10%;
            transition: margin-left 1s;
            transition-timing-function: ease;
        }
        .conceal{
            margin-left: -270px;
            
        }
        .hide{
            display: none;
        }
        .menu_mobile_view{
            display: inline-flex;
        }

        .title-section{
            margin-right: 50px;
        }
    
        .show-drop-menu{
            background-color: #ffffff;
            width: 30px;
            border: none;
            outline: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: 50px;
            right: 0;
            font-size: 16pt;
        }
        .hide-drop-menu{
            background: none;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 16pt;
        }
        .menu_button{
            border: none;
            font-family: "Arvo", sans-serif;
            font-weight: 600;
        }
        .tab-icon{
            margin-left: 16px;
        }
        .menu_link{
            margin: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-family: "Arvo", sans-serif;
            font-weight: 600;
        }
        .header-logo{
            width:40px;
            height: 40px;
        }
        .auth{
            align-items: flex-end;
        }
    }

