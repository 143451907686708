.dashboard{
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.bt-group{
    display: flex;
    justify-content: center;
}

.group-caption{
    font-family: "Arvo", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 16px;
    text-align: center;
}

.dash-button{
    border: none;
    border-radius: 8px;
    height: 100px;
    width: 100px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 16px;
}

.dash-button:hover{
    opacity: 0.7;
}

.btn-label{
    font-size: 20px;
}

.dash-icon{
    font-size: 24px;
    margin: 8px;
}

.bt_red{
    background-color: #d62828;
}

.bt_blue{
    background-color: #366fc5;
}
 .bt_vl{
     background-color: #8642e0;
 }

 .bt_gr{
    background-color: #249b52;
 }

 .bt_gl{
    background-color: #daa520;
 }

 .bg_pnk{
    background-color: #a3542c;
 }

 .bt_blk{
    background-color: #1b2430;
 }

 .task-button{
    background: none;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 20px;
    cursor: pointer;
 }

 .task-button :hover, .task-button:hover .det-caption{
    color: #daa520;
 }

 .data-container{
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    width: 600px;
    max-width: 100vw;
    align-items: center;
 }

 .admin-infos{
    margin: 24px 0;
 }

 .info-data{
    font-size: 24px;
    font-weight: 600;
    color: #595c60;
    font-family: "Arvo", sans-serif;
 }

 .admin-avatar{
    padding: 16px;
 }

 .pill-btn{
    background-color: #2a4d5f;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    width: 200px;
    border-radius: 20px;
    padding: 8px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
 }

 .edit-cap{
    margin-left: 24px;
 }

 .admin-img{
    width: 200px;
    border-radius: 104px;
    height: 200px;
    object-fit: contain;
    border: 4px solid #2a4d5f;;
 }

 .edit-avatar{
    display: none;
 }

 .data-input{
    border: none;
    border-bottom: 1px solid #1b2430;
    background: transparent;
    padding: 8px;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    color: #595c60;
 }