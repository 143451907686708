.loader{
    animation: rotate infinite linear 0.8s;
}

.loader-page{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #2628453d;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


@keyframes rotate {
     from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}