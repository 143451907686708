.service-box{
    border: 1px solid #adb3b5;
    width: 300px;
    height: 350px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 8px ;
    cursor: pointer;
    background-color: #ffffff;
}

.service-box:hover{
    border: 1px solid #0f769f;
    z-index:99;
}
.service-caption{
    font-family: "Arvo", sans-serif;
    font-size: 24px;
    margin-top: 16px;
    font-weight: 600;
    text-shadow: 2px #224b5b;
    color: #0c1417;
    text-align: center;
}

.line{
    margin: 8px 0;
    height: 3px;
    background-color: #0c1417;
    width: 80px;
}

.enlarged{
    animation: enlarge 0.3s linear forwards;
}

.service-text{
    color: #6d6969;
    font-family: "Arvo", sans-serif;
    line-height: 24px;
    text-align: justify;
    padding: 8px;
}

@keyframes enlarge {
    from{
        transform: scaleX(1);
        margin-top: 8px;
    }
    to{
        transform: scaleX(3);
        margin-top: 16px;
    }
}

.services-box{
    width: 800px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 16px auto;
}

.services-wrapper{
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    margin: auto;
    align-self: center;
    width: 950px;
    max-width: 95vw;
}

@media(max-width:400px){
    .services-wrapper{
            width: 300px;
    }
}