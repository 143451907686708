.form-button{
    height: 31px;
    border-radius: 8px;
    background-color: #2e3167;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #2e3167;
    width: 100%;
    height: 100%;
}

.input-container{
    border-radius: 8px;
    height: 35px;
    border: 1px solid #2e3167;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 0 8px;
}

.input-field{
    background: transparent;
    border: none;
    outline: none;
    flex-grow: 1;
}

.input-caption{
    font-weight: 600;
    margin-right: 8px;
}

.form-container{
    box-shadow: 0px 3px 1px 0px #2e3167, 0px 1px 2px 0px #2e3167;
    width: 400px;
    max-width: 94vw;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 16px 0;
}

.auth-form{
    margin: 16px;
}

.form-caption{
    font-family: "Ubuntu", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #2e3167;
    margin: 16px 0;
    text-align: center;
}

.button-container{
    height: 35px;
    border: 1px solid #2e3167;
    border-radius: 8px;
    background-color: #2e3167;
    margin: 16px 0;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.recovery-propmpt{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}