.info-bar{
    background-color: #141313;
    height: 50px;
    color: #efbd40;
    display: flex;
    flex-direction: row;
    align-items: center;
    scrollbar-width: 0;
}

.map-icon{
    padding: 4px 16px;
    display: flex;
    align-items: center;
}

.loc{
    margin-right: 8px;
}

.loc-text{
    font-size: 16px;
    font-weight: 500;
}

@media(max-width: 768px){
    .info-bar{
        display: none;
    }
}