.page-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100vw;
}

.paging-btn{
    border: none;
    outline: none;
    background-color: #1b5ec2;
    font-weight: 500;
    color: #ffff;
    padding: 6px;
    margin: 0 16px;
}

.pg-num{
    border: 1px solid #232323;
    padding: 4px 10px;
    margin: 0 8px;
}

.pagin-cap{
    margin: 0 8px;
}

.blured{
    opacity: 0.7;
    cursor: not-allowed;
}